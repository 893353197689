//Can add more themes from here
export const ALL_THEMES = [
  {
    themeName: "Light",
    colors: {
      "--color-background": "#ffffff",
      "--color-primary": "#00d1b2",
      "--color-primary-light": "#00d1b238",
      "--color-primary-dark": "#049b84",
      "--color-secondary": "#7bccca",
      "--color-tertiary": "#ff94c4",
      "--color-text-primary": "black",
      "--color-text-secondary": "#363636",
      "--color-nav-hover": "#ccccccd5",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#c5c4c2",
      "--transparent": "#ffffffbf",
    },
  },
  {
    themeName: "Dark",
    colors: {
      "--color-background": "black",
      "--color-primary": "#eb5757",
      "--color-primary-light": "#f28e8e31",
      "--color-primary-dark": "#eb3c3c",
      "--color-secondary": "#f9bf52",
      "--color-tertiary": "#1ab7ea",
      "--color-text-primary": "#ffffffe5",
      "--color-text-secondary": "#9a989a",
      "--color-nav-hover": "#1a1f28",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#2c323f",
      "--transparent": "#111111bf",
    },
  },
  {
    themeName: "Sunrise",
    colors: {
      "--color-background": "#ecd2c5",
      "--color-primary": "#4f2733",
      "--color-primary-light": "#72525C5C",
      "--color-primary-dark": "#201014",
      "--color-secondary": "#685844",
      "--color-tertiary": "#f3e8e5",
      "--color-text-primary": "black",
      "--color-text-secondary": "#363636",
      "--color-nav-hover": "#957d85",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#c5c4c2",
      "--transparent": "#ffffffbf",
    },
  },
  // {
  //   themeName: "Sunset",
  //   colors: {
  //     "--color-background": "#151c19",
  //     "--color-primary": "#C0AB92",
  //     "--color-primary-light": "#E0D5C955",
  //     "--color-primary-dark": "#867866",
  //     "--color-secondary": "#685844",
  //     "--color-tertiary": "#2f3834",
  //     "--color-text-primary": "#ecd2c5",
  //     "--color-text-secondary": "#C0AB92",
  //     "--color-nav-hover": "#E0D5C9A7",
  //     "--color-gradient": "#303437",
  //     "--color-dark-gray": "#c5c4c2",
  //     "--transparent": "#ffffffbf",
  //   },
  // },
  {
    themeName: "Purple",
    colors: {
      "--color-background": "#1e3253",
      "--color-primary": "#d884ff",
      "--color-primary-light": "#e4aaff3f",
      "--color-primary-dark": "#c243fd",
      "--color-secondary": "#eb505a",
      "--color-tertiary": "#aac86e",
      "--color-text-primary": "#ffffff",
      "--color-text-secondary": "#a0acbc",
      "--color-nav-hover": "#2c4c78",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#2c4c78",
      "--transparent": "#21315bc5",
    },
  },
  {
    themeName: "Green",
    colors: {
      "--color-background": "#ecf5e3",
      "--color-primary": "#2d9641",
      "--color-primary-light": "#59976446",
      "--color-primary-dark": "#129029",
      "--color-secondary": "#b8723f",
      "--color-tertiary": "#b33938",
      "--color-text-primary": "#303e22",
      "--color-text-secondary": "#809070",
      "--color-nav-hover": "#c7e2ab",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#c7e2ab",
      "--transparent": "#f0f0d57e",
    },
  },
  {
    themeName: "Gray",
    colors: {
      "--color-background": "#313437",
      "--color-primary": "#eb5757",
      "--color-primary-light": "#553b3d",
      "--color-primary-dark": "#f28e8e",
      "--color-secondary": "#f9bf52",
      "--color-tertiary": "#069ccd",
      "--color-text-primary": "#ebebeb",
      "--color-text-secondary": "#a0a2a3",
      "--color-nav-hover": "#474c50",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#474c50",
      "--transparent": "#303437bf",
    },
  },
  {
    themeName: "Maroon",
    colors: {
      "--color-background": "#621832",
      "--color-primary": "#ffd845",
      "--color-primary-light": "#fce3891e",
      "--color-primary-dark": "#fbd344",
      "--color-secondary": "#d44159",
      "--color-tertiary": "#ceaaef",
      "--color-text-primary": "#fff",
      "--color-text-secondary": "#b7a0ab",
      "--color-nav-hover": "#79495c",
      "--color-gradient": "#303437",
      "--color-dark-gray": "#79495c",
      "--transparent": "#621832bf",
    },
  },
];

// These things don't change that often, that's why I'm storing them here instead of in DB.
export const GAMES = [
  {
    no: 0,
    id: "csgo",
    name: "Counter Strike : Global Offensive",
    shortDescription:
      "Always loved this game. Lot of childhood memories connected to it.",
    gameId: "ID: 1086915975",
  },
  {
    no: 1,
    id: "valorant",
    name: "Valorant",
    shortDescription: "Basically counter strike but modern 😆.",
    gameId: "ID: Oopskiller#2415",
  },
];

export const BOOK_RECOMMENDATIONS = [
  {
    no: 0,
    id: "startwithwhy",
    name: "Start With WHY",
    shortDescription: "Simon Sinek",
    rank: "Rank #1",
  },
  {
    no: 1,
    id: "psychologyofmoney",
    name: "Psychology of Money",
    shortDescription: "Morgan Housel",
    rank: "Rank #2",
  },
  {
    no: 2,
    id: "thekiterunner",
    name: "The Kite Runner",
    shortDescription: "Khaled Hosseini",
    rank: "Rank #3",
  },
  {
    no: 3,
    id: "uncletom",
    name: "Uncle Tom's Cabin",
    shortDescription: "Harriet Beecher Stowe",
    rank: "Rank #4",
  },
  {
    no: 4,
    id: "tokillamockingbird",
    name: "To Kill a Mockingbird",
    shortDescription: "Harper Lee",
    rank: "Rank #5",
  },
  {
    no: 5,
    id: "theboyinstripedpajamas",
    name: "The Boy in the Striped Pajamas",
    shortDescription: "John Boyne",
    rank: "Rank #6",
  },
  {
    no: 6,
    id: "thehungergames",
    name: "The Hunger Games Series",
    shortDescription: "Suzanne Collins",
    rank: "Rank #7",
  },
  {
    no: 8,
    id: "1984",
    name: "1984",
    shortDescription: "George Orwell",
    rank: "Rank #8",
  },
];
