import React, { useEffect } from "react";
import classes from "./Bio.module.scss";
import CenterDiv from "../../components/CenterDiv/CenterDiv";
import { Footer } from "../../components/Footer/Footer";
import profileImg from "./../../img/profile-image.jpg";

function Bio() {
  useEffect(() => {
    document.title = "Musaib's Bio Data";
  }, []);

  return (
    <div className={classes["bio"]}>
      <CenterDiv
        extraStyles={{
          padding: "10rem 0 6rem 0",
          borderBottom: "1px solid var(--color-dark-gray)",
          "@media(max-width:425px)": {
            marginTop: "10rem",
            flexDirection: "column-reverse",
            justifyContent: "center",
          },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className={classes["bio__section-header"]}>
          <svg
            viewBox="0 0 312 312"
            fill="none"
            className={classes["header__svg"]}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M146.021 6.72671C149.203 3.14658 154.797 3.14658 157.979 6.7267L185.053 37.185C186.976 39.3486 189.918 40.3044 192.746 39.6845L232.552 30.9569C237.231 29.931 241.757 33.2191 242.227 37.986L246.227 78.541C246.511 81.4217 248.33 83.9242 250.982 85.0847L288.315 101.421C292.704 103.342 294.432 108.662 292.011 112.795L271.41 147.956C269.946 150.453 269.946 153.547 271.41 156.044L292.011 191.205C294.432 195.338 292.704 200.658 288.315 202.579L250.982 218.915C248.33 220.076 246.511 222.578 246.227 225.459L242.227 266.014C241.757 270.781 237.231 274.069 232.552 273.043L192.746 264.315C189.918 263.696 186.976 264.651 185.053 266.815L157.979 297.273C154.797 300.853 149.203 300.853 146.021 297.273L118.947 266.815C117.024 264.651 114.082 263.696 111.254 264.315L71.4478 273.043C66.7689 274.069 62.2434 270.781 61.7732 266.014L57.7728 225.459C57.4886 222.578 55.6704 220.076 53.0185 218.915L15.6846 202.579C11.2963 200.658 9.56767 195.338 11.9892 191.205L32.5904 156.044C34.0538 153.547 34.0538 150.453 32.5904 147.956L11.9892 112.795C9.56768 108.662 11.2963 103.342 15.6846 101.421L53.0185 85.0847C55.6704 83.9242 57.4886 81.4217 57.7728 78.541L61.7732 37.986C62.2434 33.2191 66.7689 29.931 71.4478 30.9569L111.254 39.6845C114.082 40.3044 117.024 39.3486 118.947 37.185L146.021 6.72671Z"
              className={classes["shadow"]}
              fill="var(--grey200)"
            ></path>
            <path
              d="M146.021 6.72671C149.203 3.14658 154.797 3.14658 157.979 6.7267L185.053 37.185C186.976 39.3486 189.918 40.3044 192.746 39.6845L232.552 30.9569C237.231 29.931 241.757 33.2191 242.227 37.986L246.227 78.541C246.511 81.4217 248.33 83.9242 250.982 85.0847L288.315 101.421C292.704 103.342 294.432 108.662 292.011 112.795L271.41 147.956C269.946 150.453 269.946 153.547 271.41 156.044L292.011 191.205C294.432 195.338 292.704 200.658 288.315 202.579L250.982 218.915C248.33 220.076 246.511 222.578 246.227 225.459L242.227 266.014C241.757 270.781 237.231 274.069 232.552 273.043L192.746 264.315C189.918 263.696 186.976 264.651 185.053 266.815L157.979 297.273C154.797 300.853 149.203 300.853 146.021 297.273L118.947 266.815C117.024 264.651 114.082 263.696 111.254 264.315L71.4478 273.043C66.7689 274.069 62.2434 270.781 61.7732 266.014L57.7728 225.459C57.4886 222.578 55.6704 220.076 53.0185 218.915L15.6846 202.579C11.2963 200.658 9.56767 195.338 11.9892 191.205L32.5904 156.044C34.0538 153.547 34.0538 150.453 32.5904 147.956L11.9892 112.795C9.56768 108.662 11.2963 103.342 15.6846 101.421L53.0185 85.0847C55.6704 83.9242 57.4886 81.4217 57.7728 78.541L61.7732 37.986C62.2434 33.2191 66.7689 29.931 71.4478 30.9569L111.254 39.6845C114.082 40.3044 117.024 39.3486 118.947 37.185L146.021 6.72671Z"
              fill="var(--color-primary)"
            ></path>
            <path
              d="M123.872 84.3044C123.972 78.974 116.87 77.0653 114.284 81.7274L77.8499 147.41C75.9791 150.783 78.3714 154.934 82.2273 155.006L102.13 155.379C104.955 155.432 107.201 157.764 107.149 160.589L106.301 205.868C106.201 211.198 113.303 213.107 115.889 208.445L152.323 142.762C154.194 139.389 151.801 135.238 147.945 135.166L128.043 134.794C125.218 134.741 122.971 132.408 123.024 129.583L123.872 84.3044Z"
              className={classes["shadow"]}
              fill="rgba(0,0,0,0.2)"
            ></path>
            <path
              d="M200.233 114.651C196.842 116.3 195.525 120.432 192.893 128.695L178.768 173.026C176.653 179.663 175.596 182.982 176.681 185.849C176.87 186.348 177.104 186.828 177.38 187.285C178.965 189.909 182.229 191.125 188.756 193.559C195.283 195.993 198.547 197.21 201.463 196.265C201.97 196.1 202.462 195.89 202.931 195.637C205.628 194.18 207.002 190.979 209.749 184.577L228.096 141.821C231.516 133.852 233.226 129.867 231.742 126.4C230.259 122.932 226.196 121.417 218.07 118.388L215.814 117.546C207.687 114.516 203.624 113.001 200.233 114.651Z"
              className={classes["shadow"]}
              fill="rgba(0,0,0,0.2)"
            ></path>
            <path
              d="M169.514 202.751C167.968 204.176 167.111 206.475 165.397 211.071C163.683 215.668 162.826 217.967 163.061 220.056C163.271 221.916 164.068 223.661 165.337 225.037C166.762 226.583 169.061 227.44 173.657 229.154L176.864 230.35C181.461 232.064 183.76 232.921 185.849 232.685C187.709 232.476 189.454 231.679 190.83 230.41C192.376 228.985 193.233 226.686 194.947 222.089C196.661 217.493 197.518 215.194 197.282 213.105C197.073 211.245 196.276 209.5 195.007 208.124C193.582 206.578 191.283 205.721 186.687 204.007L183.48 202.811C178.883 201.097 176.584 200.24 174.495 200.476C172.635 200.685 170.89 201.482 169.514 202.751Z"
              className={classes["shadow"]}
              fill="rgba(0,0,0,0.2)"
            ></path>
            <path
              d="M123.872 84.3044C123.972 78.974 116.87 77.0653 114.284 81.7274L77.8499 147.41C75.9791 150.783 78.3714 154.934 82.2273 155.006L102.13 155.379C104.955 155.432 107.201 157.764 107.149 160.589L106.301 205.868C106.201 211.198 113.303 213.107 115.889 208.445L152.323 142.762C154.194 139.389 151.801 135.238 147.945 135.166L128.043 134.794C125.218 134.741 122.971 132.408 123.024 129.583L123.872 84.3044Z"
              fill="var(--grey0)"
            ></path>
            <path
              d="M200.233 114.651C196.842 116.3 195.525 120.432 192.893 128.695L178.768 173.026C176.653 179.663 175.596 182.982 176.681 185.849C176.87 186.348 177.104 186.828 177.38 187.285C178.965 189.909 182.229 191.125 188.756 193.559C195.283 195.993 198.547 197.21 201.463 196.265C201.97 196.1 202.462 195.89 202.931 195.637C205.628 194.18 207.002 190.979 209.749 184.577L228.096 141.821C231.516 133.852 233.226 129.867 231.742 126.4C230.259 122.932 226.196 121.417 218.07 118.388L215.814 117.546C207.687 114.516 203.624 113.001 200.233 114.651Z"
              fill="var(--grey0)"
            ></path>
            <path
              d="M169.514 202.751C167.968 204.176 167.111 206.475 165.397 211.071C163.683 215.668 162.826 217.967 163.061 220.056C163.271 221.916 164.068 223.661 165.337 225.037C166.762 226.583 169.061 227.44 173.657 229.154L176.864 230.35C181.461 232.064 183.76 232.921 185.849 232.685C187.709 232.476 189.454 231.679 190.83 230.41C192.376 228.985 193.233 226.686 194.947 222.089C196.661 217.493 197.518 215.194 197.282 213.105C197.073 211.245 196.276 209.5 195.007 208.124C193.582 206.578 191.283 205.721 186.687 204.007L183.48 202.811C178.883 201.097 176.584 200.24 174.495 200.476C172.635 200.685 170.89 201.482 169.514 202.751Z"
              fill="var(--grey0)"
            ></path>
          </svg>
          <h1 className={classes["heading__primary"]}>Bio Data</h1>
        </div>
        <div style={{ alignSelf: "center" }}>
          <img
            src={profileImg}
            className={classes["bio__profile--image"]}
            alt="Musaib's profile"
          />
        </div>
      </CenterDiv>

      <CenterDiv
        extraStyles={{
          padding: "5rem 0 5rem 0",
          borderBottom: "1px solid var(--color-dark-gray)",
        }}
      >
        <h2 className={classes["heading__secondary"]}>Personal Details</h2>
        <table style={{ width: "100%" }}>
          <colgroup>
            <col span="1" style={{ width: "40%" }} />
            <col span="1" style={{ width: "60%" }} />
          </colgroup>

          <tbody>
            <tr>
              <td>Name</td>
              <td>Mohammed Musaib Ali</td>
            </tr>
            <tr>
              <td>Qualification</td>
              <td>Bachelor of Engineering in Computer Science</td>
            </tr>
            <tr>
              <td>Occupation</td>
              <td>Frontend Developer at Cloudfabrix</td>
            </tr>
            <tr>
              <td>Date of birth</td>
              <td>06 August 2001</td>
            </tr>
            <tr>
              <td>Age</td>
              <td>23</td>
            </tr>
            <tr>
              <td>Height</td>
              <td>5'7</td>
            </tr>
          </tbody>
        </table>
      </CenterDiv>

      <CenterDiv
        extraStyles={{
          padding: "5rem 0 5rem 0",
          borderBottom: "1px solid var(--color-dark-gray)",
        }}
      >
        <h2 className={classes["heading__secondary"]}>Family Details</h2>
        <table style={{ width: "100%" }}>
          <colgroup>
            <col span="1" style={{ width: "40%" }} />
            <col span="1" style={{ width: "60%" }} />
          </colgroup>

          <tbody>
            <tr>
              <td>Father's Name</td>
              <td>Mohammed Riyaz Ali</td>
            </tr>
            <tr>
              <td>Occupation</td>
              <td>
                City Times and Communication, Darshan Tower, Near Kharge Pump,
                Gulbarga
              </td>
            </tr>
            <tr>
              <td>Mother's Name</td>
              <td>Sameena Nazneen</td>
            </tr>
            <tr>
              <td>Occupation</td>
              <td>Teacher at Govt. Urdu HPS, Kurakunta</td>
            </tr>
            <tr>
              <td>Siblings</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Paternal Grand Father</td>
              <td>Late. Mohammed Ali, Teacher, Sedam</td>
            </tr>
            <tr>
              <td>Maternal Grand Father</td>
              <td>Late. Mohammed Abdus Samad, Sedam</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                F-18, 4th cross, Behind Baquers function, M.B Nagar, Gulbarga
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                9481537636
                <br />
                9901097085
              </td>
            </tr>
          </tbody>
        </table>

        <div className={classes["bio__button-container"]}>
          <a
            href="https://drive.google.com/uc?export=download&id=1S-fhRDejHm2P8TGvdLUEnMsGOMA-HJXf"
            className={classes["button--download"]}
            rel="noreferrer"
          >
            Download Bio Data
          </a>
        </div>
      </CenterDiv>

      <CenterDiv
        extraStyles={{
          padding: " 6rem 0",
        }}
      >
        <Footer />
      </CenterDiv>
    </div>
  );
}

export default Bio;
